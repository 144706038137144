<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <!-- Modal View Log -->
    <b-modal
      :id="modalLog.id"
      :title="modalLog.title"
      hide-footer
      size="xl"
    >
      <b-overlay
        :show="show"
        spinner-variant="primary"
        spinner-type="border"
        spinner-large
        rounded="lg"
      >
        <b-container>
          <div>
            <b-badge
              class="mr-1"
              variant="success"
            >
              Sucesso
            </b-badge>
            <b-badge
              class="mr-1"
              variant="danger"
            >
              Falha
            </b-badge>
            <b-badge
              class="mr-1"
              variant="warning"
            >
              Não chegou executar
            </b-badge>
          </div>
        </b-container>

        <b-tabs
          pills
          card
          vertical
          nav-wrapper-class="w-10"
        >
          <b-tab
            v-for="(item, index) in viewlogItems"
            :key="index"
            :active="index === 0"
          >

            <template #title>
              <b-container>
                <b-badge
                  v-if="item.status == 'success'"
                  variant="success"
                >
                  <h6><strong> {{ item.task_name }}</strong></h6>
                </b-badge>
                <b-badge
                  v-if="item.status == 'failed'"
                  variant="danger"
                >
                  <h6><strong> {{ item.task_name }}</strong></h6>
                </b-badge>
                <b-badge
                  v-if="item.status == 'upstream_failed'"
                  variant="warning"
                >
                  <h6><strong> {{ item.task_name }}</strong></h6>
                </b-badge>
              </b-container>

            </template>
            <b-card-text>
              <!-- <h3>
                <b-badge variant="info">
                  {{ item.description }}
                </b-badge>
              </h3> -->
              <br>
              <ul style="list-style: none; overflow-x: auto;">
                Descrição : {{ item.description }}
                <br>
                <br>
                <li
                  v-for="(logLine, index) in item.task_log.split('<br')"
                  :key="index"
                >
                  {{ logLine.replace(/>\s*/g, '') }}<br><br>
                </li>
              </ul>
            </b-card-text>
          </b-tab>
        </b-tabs>

        <b-button
          id=""
          variant="primary"
          type="button"
          @click="$bvModal.hide('modalLog')"
        >
          Cancelar
        </b-button>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BButton, BModal, BOverlay, BTabs, BTab, BBadge, BContainer,
} from 'bootstrap-vue'

export default {
  name: 'ModalViewLog',
  components: {
    BContainer,
    BTab,
    BTabs,
    BOverlay,
    BButton,
    BModal,
    BBadge,
  },
  data() {
    return {
      show: false,
      modalLog: {
        id: 'modalLog',
        title: '',
        content: '',
      },
      viewlogFields: [
        { key: 'task_name', label: 'Task Name' },
        { key: 'task_log', label: 'Task Log' },
      ],
      viewlogItems: [

      ],

    }
  },
  methods: {
    async getLogs(cnpj, dagId, dagRunId) {
      this.show = true // loading page b-overlay
      try {
        const res = await axios.get(`/airflow/getLog/${cnpj}/${dagId}/${dagRunId}`)
        this.viewlogItems = res.data
        console.log(res.data)
      } catch (error) {
        console.log(error)
      }
      this.show = false
    },
  },
}
</script>
